<template>
	<div v-loading="fullscreenLoading">
		<!--收货地址-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 0">
			<div class="block-text">{{ $lang('tmdDOTco.01636', $route) }}</div>

			<div clsas="address-list">
				<div class="address-item" @click="addAddressShow">
					<div class="add-address">
						<i class="el-icon-circle-plus-outline"></i>
						{{ $lang('tmdDOTco.01637', $route) }}
					</div>
				</div>

				<div class="address-item" v-for="(item, key) in memberAddress" :key="item.id" :class="addressId == item.id ? 'active' : ''"
				 v-if="key < 3 || (addressShow && key >= 3)">
					<div class="address-info">
						<div class="options">
							<div @click="editAddress(item.id)">{{ $lang('tmdDOTco.01638', $route) }}</div>
							<template v-if="item.is_default == 0">
								<el-popconfirm :title="$lang('tmdDOTco.01639', $route)" @onConfirm="deleteMemberAddress(item.id)">
									<div slot="reference">{{ $lang('tmdDOTco.01640', $route) }}</div>
								</el-popconfirm>
							</template>
						</div>
						<div class="address-name">{{ item.name }}</div>
						<div class="address-mobile" @click="setMemberAddress(item.id)">{{ item.mobile }}</div>
						<div class="address-desc" @click="setMemberAddress(item.id)">{{ item.full_address }} {{ item.address }}</div>
					</div>
				</div>

				<div v-if="memberAddress.length > 3 && !addressShow" @click="addressShow = true" class="el-button--text address-open">
					<i class="el-icon-arrow-down"></i>
					{{ $lang('tmdDOTco.01641', $route) }}
				</div>

				<div class="clear"></div>
			</div>
		</div>

		<!--购买虚拟类商品需填写您的手机号-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 1">
			<div class="block-text">{{ $lang('tmdDOTco.01642', $route) }}</div>

			<el-form ref="form" size="mini" class="mobile-wrap" label-width="80px">
				<el-form-item :label="$lang('tmdDOTco.01643', $route)">
					<el-input :placeholder="$lang('tmdDOTco.01644', $route)" maxlength="11" v-model="orderCreateData.member_address.mobile"></el-input>
				</el-form-item>
			</el-form>
		</div>

		<!--收货地址添加-->
		<el-dialog :title="addressForm.id == 0 ? $lang('tmdDOTco.01645', $route) : $lang('tmdDOTco.01646', $route)" :visible.sync="dialogVisible" width="32%">
			<el-form ref="form" :rules="addressRules" :model="addressForm" label-width="80px">
				<el-form-item :label="$lang('tmdDOTco.01647', $route)" prop="name">
					<el-input v-model="addressForm.name" :placeholder="$lang('tmdDOTco.01648', $route)"></el-input>
				</el-form-item>

				<el-form-item :label="$lang('tmdDOTco.01649', $route)" prop="mobile">
					<el-input v-model="addressForm.mobile" maxlength="11" :placeholder="$lang('tmdDOTco.01650', $route)"></el-input>
				</el-form-item>

				<el-form-item :label="$lang('tmdDOTco.01651', $route)">
					<el-input v-model="addressForm.telephone" :placeholder="$lang('tmdDOTco.01652', $route)"></el-input>
				</el-form-item>

				<el-form-item class="area" :label="$lang('tmdDOTco.01653', $route)" prop="area">
					<el-row :gutter="10">
						<el-col :span="7">
							<el-select prop="province" ref="province" v-model="addressForm.province_id" @change="getAddress(1)" :placeholder="$lang('tmdDOTco.01654', $route)">
								<el-option :label="$lang('tmdDOTco.01655', $route)" value="0"></el-option>
								<el-option v-for="item in pickerValueArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="city" prop="city" v-model="addressForm.city_id" @change="getAddress(2)" :placeholder="$lang('tmdDOTco.01656', $route)">
								<el-option :label="$lang('tmdDOTco.01657', $route)" value="0"></el-option>
								<el-option v-for="item in cityArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="district" prop="district" v-model="addressForm.district_id" :placeholder="$lang('tmdDOTco.01658', $route)">
								<el-option :label="$lang('tmdDOTco.01659', $route)" value="0"></el-option>
								<el-option v-for="item in districtArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
					</el-row>
				</el-form-item>

				<el-form-item :label="$lang('tmdDOTco.01660', $route)" prop="address">
					<el-input v-model="addressForm.address" :placeholder="$lang('tmdDOTco.01661', $route)"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">{{ $lang('tmdDOTco.01662', $route) }}</el-button>
				<el-button type="primary" @click="addmemberAddress('form')">{{ $lang('tmdDOTco.01663', $route) }}</el-button>
			</span>
		</el-dialog>

		<!--使用余额-->
		<div class="item-block" v-if="orderPaymentData.member_account.balance_total > 0 && balance_show == 1">
			<div class="block-text">{{ $lang('tmdDOTco.01664', $route) }}</div>

			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_balance ? '' : 'active'" @click="useBalance(0)">{{ $lang('tmdDOTco.01665', $route) }}</div>
				<div class="pay-type-item" :class="orderCreateData.is_balance ? 'active' : ''" @click="useBalance(1)">{{ $lang('tmdDOTco.01666', $route) }}</div>
				<div class="clear"></div>
			</div>
		</div>


		<!-- 配送方式 -->
		<div class="item-block padd-bom-20" v-if="orderPaymentData.is_virtual == 0">
			<div class="pay-type-list">{{ $lang('tmdDOTco.01667', $route) }}
				<span class="distribution" v-if="orderPaymentData.delivery_type == 'store' && orderCreateData.delivery.store_name">{{ orderCreateData.delivery.store_name }}</span>
			</div>
			<div v-if="orderPaymentData.shop_goods_list.express_type.length > 0">
				<div class="pay-type-item" v-for="(deliveryItem, deliveryIndex) in orderPaymentData.shop_goods_list.express_type"
				 :key="deliveryIndex" @click="selectDeliveryType(deliveryItem)" :class="orderPaymentData.delivery_type == deliveryItem.name ? 'active' : ''"
				 v-if="deliveryItem.name != 'local'">
					{{ deliveryItem.title }}
				</div>
			</div>
			<div v-else-if="memberAddress.length == 0">
				<div class="box ns-text-color">{{ $lang('tmdDOTco.01668', $route) }}</div>
			</div>
			<div v-else>
				<div class="box ns-text-color">{{ $lang('tmdDOTco.01669', $route) }}</div>
			</div>

		</div>

		<!--商品信息-->
		<div class="item-block">
			<div class="goods-list">
				<table>
					<tr>
						<td width="70%">{{ $lang('tmdDOTco.01670', $route) }}</td>
						<td width="15%">{{ $lang('tmdDOTco.01671', $route) }}</td>
						<td width="15%">{{ $lang('tmdDOTco.01672', $route) }}</td>
					</tr>
				</table>
			</div>
		</div>

		<div>
			<div class="item-block">
				<div class="goods-list">
					<table>
						<tr v-for="(goodsItem, goodsIndex) in orderPaymentData.shop_goods_list.goods_list" :key="goodsIndex">
							<td width="70%">
								<div class="goods-info">
									<div class="goods-info-left">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<img class="goods-img" :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(goodsIndex)" />
										</router-link>
									</div>
									<div class="goods-info-right">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<div class="goods-name">{{ goodsItem.sku_name }}</div>
										</router-link>
										<!-- 规格 -->
										<div class="goods-spec" v-if="goodsItem.sku_spec_format">
											<span v-for="(x, i) in goodsItem.sku_spec_format" :key="i">{{ x.spec_value_name }} {{ i < goodsItem.sku_spec_format.length - 1 ? '; ' : '' }}</span>
										</div>
									</div>
								</div>
							</td>
							<td width="15%" class="goods-price">{{ $lang('components.currency_symbol_left', $route) + goodsItem.price + $lang('components.currency_symbol_right', $route) }}</td>
							<td width="15%" class="goods-num">{{ goodsItem.num }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>




		<!--优惠-->
		<div class="item-block">
			<div class="block-text">{{ $lang('tmdDOTco.01673', $route) }}</div>
			<div class="order-cell platform-coupon">
				<span class="ns-text-color">{{ $lang('tmdDOTco.01674L', $route) + orderPaymentData.groupbuy_info.buy_num + $lang('tmdDOTco.01674R', $route) + orderPaymentData.groupbuy_info.groupbuy_price }}</span>
			</div>
		</div>

		<!-- 买家留言 -->
		<div class="item-block padd-bom-10">
			<div class="block-text">{{ $lang('tmdDOTco.01675', $route) }}</div>
			<el-input rows="3" type="textarea" :placeholder="$lang('tmdDOTco.01676', $route)" v-model="orderCreateData.buyer_message" class="buyer-message"
			 @input="textarea" maxlength="140" show-word-limit resize="none"></el-input>
		</div>

		<!-- 总计 -->
		<div class="item-block">
			<div class="order-statistics">
				<table>
					<tr>
						<td align="right">{{ $lang('tmdDOTco.01677', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.goods_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<tr v-if="orderPaymentData.is_virtual == 0">
						<td align="right">{{ $lang('tmdDOTco.01678', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.delivery_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<tr v-if="orderPaymentData.invoice_money > 0">
						<td align="right">{{ $lang('tmdDOTco.01679', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.invoice_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<tr v-if="orderPaymentData.promotion_money > 0">
						<td align="right">{{ $lang('tmdDOTco.01680', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.promotion_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</td>
					</tr>
					<tr v-if="orderPaymentData.balance_money > 0">
						<td align="right">{{ $lang('tmdDOTco.01681', $route) }}</td>
						<td align="left">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.balance_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</td>
					</tr>
				</table>
			</div>
			<div class="clear"></div>
		</div>

		<!--结算-->
		<div class="item-block">
			<div class="order-submit">
				<div class="order-money">
					{{ $lang('tmdDOTco.01680L', $route) + orderPaymentData.goods_num + $lang('tmdDOTco.01680R', $route) }}
					<div class="ns-text-color">{{ $lang('components.currency_symbol_left', $route) + (orderPaymentData.pay_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</div>
				</div>
				<el-button type="primary" class="el-button--primary" @click="orderCreate">{{ $lang('tmdDOTco.01683', $route) }}</el-button>
			</div>
			<div class="clear"></div>
		</div>

		<!--配送方式-->
		<el-dialog :title="$lang('tmdDOTco.01684', $route)" :visible.sync="dialogStore" width="50%">
			<el-table ref="singleTable" :data="storeList" highlight-current-row @row-click="selectStore" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<el-radio v-model="storeRadio" :label="scope.row"><i></i></el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="store_name" :label="$lang('tmdDOTco.01685', $route)" width="160"></el-table-column>
				<el-table-column prop="store_address" :label="$lang('tmdDOTco.01686', $route)"></el-table-column>
				<el-table-column prop="open_date" :label="$lang('tmdDOTco.01687', $route)"></el-table-column>
			</el-table>
		</el-dialog>

		<!-- 配送方式 外卖 -->
		<el-dialog :title="$lang('tmdDOTco.01688', $route)" :visible.sync="deliveryTime" width="400px">
			<el-form status-icon ref="ruleForm" label-width="100px">
				<el-form-item :label="$lang('tmdDOTco.01689', $route)">
					<el-col :span="11">
						<el-time-picker format="HH:mm" value-format="HH:mm" :placeholder="$lang('tmdDOTco.01690', $route)" :value="time" v-model="time" start="09:01"
						 end="21:01" @change="bindTimeChange"></el-time-picker>
					</el-col>
				</el-form-item>
				<el-form-item label="">
					<el-col :span="20">
						<div class="align-right">
							<el-button size="small" @click="deliveryTime = false">{{ $lang('tmdDOTco.01691', $route) }}</el-button>
							<el-button type="primary" size="small" @click="setDeliveryTime(siteDelivery.site_id)">{{ $lang('tmdDOTco.01692', $route) }}</el-button>
						</div>
					</el-col>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 支付密码 -->
		<el-dialog :title="$lang('tmdDOTco.01693', $route)" :visible.sync="dialogpay" width="350px">
			<template v-if="orderPaymentData.member_account.is_pay_password == 0">
				<p>{{ $lang('tmdDOTco.01694', $route) }}</p>
				<p>{{ $lang('tmdDOTco.01695', $route) }}</p>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="dialogpay = false">{{ $lang('tmdDOTco.01696', $route) }}</el-button>
					<el-button size="small" type="primary" @click="setPayPassword">{{ $lang('tmdDOTco.01697', $route) }}</el-button>
				</span>
			</template>
			<el-form v-else status-icon ref="ruleForm" label-width="100px">
				<el-form-item :label="$lang('tmdDOTco.01698', $route)" class="pay-password-item">
					<!--添加一个不可见的input,欺骗浏览器自动填充-->
					<el-input type="password" class="pay-password hide-password" :maxlength="6"></el-input>
					<el-input type="password" class="pay-password" :maxlength="6" v-model="password" @input="input"></el-input>
				</el-form-item>
				<p class="ns-text-color forget-password" @click="setPayPassword">{{ $lang('tmdDOTco.01699', $route) }}</p>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import detail from './payment_groupbuy.js';
	export default {
		name: 'groupbuy_payment',
		mixins: [detail]
	};
</script>

<style lang="scss" scoped>
	@import './payment_groupbuy.scss';
</style>
